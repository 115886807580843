import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import Breadcrumbs from '../../Breadcrumbs';
import Image from '../../../utils/OptimizedImage';

const KeyImageBlock = ({ result }) => {
  return (
    <Col sm="auto" className="result-block px-0 mb-3">
      <Row className="mx-0">
        <Col xs="auto" className="pl-0">
          <div className="image-wrapper">
            <Image
              src={result?.url}
              alt={result?.alt}
              title={result?.title}
            />
          </div>
        </Col>
        <Col xs="auto" className="px-0">
          <div className="result-content">{result?.title}</div>
        </Col>
      </Row>
    </Col>
  );
};

const StoryHero = ({ story }) => {
  const changeStyle = html => {
    const liRex = /<li[^>]*>(.*?)<\/li>/gi;
    let li;
    let returnHtml = html;
    // eslint-disable-next-line no-cond-assign
    while ((li = liRex.exec(returnHtml))) {
      const splitedLi = li[1].split(' ');
      splitedLi[0] = `<h3 class="highlight">${splitedLi[0]}</h3>`;
      const modified = splitedLi.join(' ');
      returnHtml = returnHtml.replace(li[1], modified);
    }
    return returnHtml;
  };
  return (
    <HeroContainer>
      <Row className="justify-content-start text-left">
        <Col lg={12} className="content-container">
          <div className="breadcrumb-wrapper">
            <Breadcrumbs />
          </div>
          <div className="tags-wrapper">
            {story?.tags.map(item => (
              <span
                className="story-tag"
                style={{ backgroundColor: item.hex || 'var(--primary-color)' }}
              >
                {item.name}
              </span>
            ))}
          </div>
          <h3>{story?.title}</h3>
          <p className="publish-date">Published On: {story?.publishDate}</p>
          <h6>KEY RESULTS</h6>
          <Row className="result-desc mx-0">
            {story.resultsIcons &&
              story.resultsIcons.length > 0 &&
              story.resultsIcons.map((result, index) => (
                <KeyImageBlock key={`result-${index + 1}`} result={result} />
              ))}
            {!story.resultsIcons && (
              <div
                className="result-desc"
                dangerouslySetInnerHTML={{ __html: changeStyle(story.results) }}
              />
            )}
          </Row>
        </Col>
      </Row>
    </HeroContainer>
  );
};
export default StoryHero;
const HeroContainer = styled(Container)`
  padding-top: 118px;
  padding-bottom: 30px;
  .breadcrumb-wrapper {
    padding-bottom: 42px;
  }
  .tags-wrapper {
    margin-bottom: 24px;
    font-size: 14.4px;
    font-weight: bold;
    color: #fff;
    .story-tag {
      padding: 4px 6px 2px;
      margin-right: 9px;
      border-radius: 2px;
      &.bg-blue {
        background: #1c3fff;
      }
      &.bg-purple {
        background: #907cff;
      }
      &.bg-cyan {
        background: #00b5ce;
      }
    }
  }
  h3 {
    max-width: 770px;
    margin-bottom: 24px;
    font-weight: bold;
    line-height: 39px;
    letter-spacing: 0;
  }
  .publish-date {
    color: #7b848f;
    font-size: 14.4px;
    margin-bottom: 80px;
  }
  h6 {
    font-weight: 900;
    font-size: 14.4px;
    line-height: 120%;
    margin-bottom: 8px;
    color: var(--text-color);
    margin-bottom: 16px;
  }
  .result-desc {
    ul {
      padding: 0;
    }
    li {
      list-style: none;
      display: inline-block;
      margin-right: 48px;
      font-size: 18px;
      line-height: 22px;
      font-weight: bold;
      .highlight {
        display: inline;
        margin-right: 6px;
      }
    }
    .result-block {
      margin-right: 32px;
      .image-wrapper {
        width: 35px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
      .result-content {
        width: 230px;
        color: #000;
      }
    }
  }
`;
